import Head from 'next/head';
import getConfig from 'next/config';

import { IMeta, ISeoExtra } from 'epromo-lib-cms/types/payload-types';

import { Favicons } from '@components/molecules/Favicons';

const { publicRuntimeConfig } = getConfig();

export type SeoProps = {
  meta: IMeta;
  seoExtra?: ISeoExtra;
  slug?: string;
};

export function PageHead({ meta, seoExtra, slug }: SeoProps) {
  if (!meta) {
    console.warn('No meta');
    return null;
  }
  const url = slug
    ? `${publicRuntimeConfig.baseUrl}/${slug}`
    : publicRuntimeConfig.baseUrl;
  return (
    <Head>
      <meta
        name="viewport"
        // eslint-disable-next-line max-len
        content="width=device-width, initial-scale=1, maximum-scale=1 user-scalable=0, viewport-fit=cover"
      />
      <link rel="dns-prefetch" href="https://epromo.imgix.net" />
      <link rel="preconnect" href="https://epromo.imgix.net" />
      {meta.title && (
        <>
          <title key="title">{meta.title}</title>
          <meta property="og:title" content={meta.title} key="og:title" />
          <meta name="twitter:title" content={meta.title} />
        </>
      )}
      {seoExtra && seoExtra.keywords && (
        <>
          <meta property="keywords" content={seoExtra.keywords} />
        </>
      )}
      {meta.description && (
        <>
          <meta name="description" content={meta.description} />
          <meta property="og:description" content={meta.description} />
          <meta name="twitter:description" content={meta.description} />
        </>
      )}
      {meta.image && (
        <>
          <meta key="og:image" property="og:image" content={meta.image.url} />
          <meta
            key="twitter:image"
            name="twitter:image"
            content={meta.image.url}
          />
          <meta name="image" content={meta.image.url} />
        </>
      )}
      {seoExtra && seoExtra.preventIndexing && (
        <>
          <meta name="robots" content="noindex"></meta>
          <meta name="googlebot" content="noindex"></meta>
        </>
      )}
      {url && (
        <>
          <meta property="og:url" content={url} key="og:url" />
          <link rel="canonical" href={url} />
        </>
      )}
      <Favicons />
    </Head>
  );
}
